<template>
  <div class="solver-container max-w-2xl mx-auto mt-6 md:mt-16 w-full px-4">
    <StatusBox
      :status="status"
      :nonces-checked="parseInt(noncesChecked)"
      :nonces-per-second="parseFloat(noncesPerSecond)"
      :challenge="currentChallenge"
      :nonce-range-start="nonceRangeStart"
      :nonce-range-end="nonceRangeEnd"
      :current-best-difficulty="currentBestDifficulty"
      :time-elapsed="timeElapsed"
      :total-solving-time="totalSolvingTime"
      :time-remaining="timeRemaining"
    />

    <div class="bg-base-200 p-4 rounded-lg shadow-md mb-6">
      <h2 class="text-lg font-semibold mb-2">API Key</h2>
      <div class="bg-base-100 p-4 rounded-lg shadow-md mb-4">
        <div class="flex items-center justify-between">
          <span class="text-base-content">{{
            showApiKey ? apiKey : maskedApiKey
          }}</span>
          <div class="flex space-x-2">
            <button
              @click="toggleApiKeyVisibility"
              class="btn btn-ghost btn-sm px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </button>
            <button @click="copyApiKey" class="btn btn-ghost btn-sm px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      @click="toggleSolving"
      class="w-full px-4 py-2 text-base-content rounded transition duration-300"
      :class="{
        'bg-primary hover:bg-primary-focus': !isSolving,
        'bg-neutral hover:bg-neutral-focus': isSolving,
      }"
    >
      {{ isSolving ? "Stop Solving" : "Start Solving" }}
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, computed } from "vue";
import { useSiteDataStore } from "@/stores/siteDataStore";
import { useSessionStore } from "@/stores/session";
import StatusBox from "@/components/solver/StatusBox";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Solver } from "@/utilities/ore/index"; // Import the Solver from your npm package

const siteDataStore = useSiteDataStore();
const sessionStore = useSessionStore();

const currentBestDifficulty = ref(0);
const status = ref("Idle");
const noncesChecked = ref(0);
const noncesPerSecond = ref(0);
const currentChallenge = ref("");
const nonceRangeStart = ref(0);
const nonceRangeEnd = ref(0);
const timeElapsed = ref(0);
const totalSolvingTime = ref(0);
const timeRemaining = ref(0);
const isSolving = ref(false);

const showApiKey = ref(false);

// Solver instance
let solver = null;

// Compute the effective API key
const effectiveApiKey = computed(() => {
  const key =
    sessionStore.user?.apiKey ||
    siteDataStore.defaultApiKey ||
    process.env.VUE_APP_X_API_KEY;
  console.log("[SolverPage] Effective API key:", key);
  return key;
});

// Use a computed property for apiKey that reflects the effectiveApiKey
const apiKey = computed(() => effectiveApiKey.value);

const maskedApiKey = computed(() => {
  if (!apiKey.value) return "••••••••••••••••";
  return `${apiKey.value.slice(0, 4)}••••••••${apiKey.value.slice(-4)}`;
});

const toggleApiKeyVisibility = () => {
  showApiKey.value = !showApiKey.value;
};

const toastTheme = computed(() =>
  sessionStore.theme === "customdark" ? "dark" : "light"
);

const copyApiKey = () => {
  navigator.clipboard
    .writeText(apiKey.value)
    .then(() => {
      toast.success("API key copied to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    })
    .catch((err) => {
      console.error("Failed to copy API key:", err);
      toast.error("Failed to copy API key", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    });
};

// Watch for changes in the effective API key
watch(effectiveApiKey, (newKey) => {
  if (solver) {
    solver.stop();
    solver = new Solver(newKey);
    if (isSolving.value) {
      solver.start();
    }
  }
});

onMounted(() => {
  window.addEventListener("workerUpdate", handleWorkerUpdate);
  window.addEventListener("solvingStatus", handleSolverStatus);
  console.log("[SolverPage] Initial API key:", apiKey.value);
  solver = new Solver(apiKey.value);
});

const handleWorkerUpdate = (event) => {
  const data = event.detail;

  if (data.status !== "Checking solutions...") {
    console.log("Worker Update Data:", data);
  }

  if (data.bestDifficulty !== undefined) {
    currentBestDifficulty.value = data.bestDifficulty;
  }

  if (data.type === "solution" && data.solution) {
    currentBestDifficulty.value = data.solution.difficulty;
  } else if (data.type === "status") {
    status.value = data.status;
    if (data.noncesChecked !== undefined)
      noncesChecked.value = data.noncesChecked;
    if (data.noncesPerSecond !== undefined)
      noncesPerSecond.value = data.noncesPerSecond;
    if (data.challenge !== undefined) currentChallenge.value = data.challenge;
    if (data.nonceStart !== undefined) nonceRangeStart.value = data.nonceStart;
    if (data.nonceEnd !== undefined) nonceRangeEnd.value = data.nonceEnd;
    if (data.timeElapsed !== undefined) timeElapsed.value = data.timeElapsed;
    if (data.totalSolvingTime !== undefined)
      totalSolvingTime.value = data.totalSolvingTime;
    if (data.timeRemaining !== undefined)
      timeRemaining.value = data.timeRemaining;
  }
};

const handleSolverStatus = (event) => {
  status.value = event.detail;
};

const toggleSolving = () => {
  if (isSolving.value) {
    solver.stop();
    // Reset values when stopping
    status.value = "Idle";
    noncesChecked.value = 0;
    noncesPerSecond.value = 0;
    currentBestDifficulty.value = 0;
    currentChallenge.value = "";
    nonceRangeStart.value = 0;
    nonceRangeEnd.value = 0;
  } else {
    solver.start();
  }
  isSolving.value = !isSolving.value;
};

onUnmounted(() => {
  window.removeEventListener("workerUpdate", handleWorkerUpdate);
  window.removeEventListener("solvingStatus", handleSolverStatus);
  if (solver) {
    solver.stop();
  }
});
</script>

<style scoped>
.solver-container {
  min-height: 100vh;
}
</style>
