<script setup>
import siteConfig from "@/siteConfig.json";
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  matchesSearch,
  updateQueryParameters,
  initializeFromQuery,
} from "@/utilities/airdrop/functions";
import { useAirdropStore } from "@/stores/airdrop";
import AirdropGrid from "@/components/airdrop/AirdropGrid.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
import { handleKeyDown } from "@/utilities/general/hotKeys";
const { pageContent, headData } = usePageData("airdrops");
useHead(headData);

const route = useRoute();
const router = useRouter();
const airdropStore = useAirdropStore();
airdropStore.initializeStore();

const selectedCategory = ref("All");

const selectSingleCategory = (category) => {
  if (category === "All") {
    airdropStore.setActiveCategories("All");
  } else {
    airdropStore.activeCategories.clear();
    airdropStore.activeCategories.add(category);
  }
  selectedCategory.value = category;
  updateQueryParameters(
    router,
    route,
    airdropStore.activeCategories,
    gridColumnCount.value
  );
};

const handleCategoryChange = () => {
  selectSingleCategory(selectedCategory.value);
};

const toggleAllCategories = () => {
  selectSingleCategory("All");
};

const updateSortOption = (option) => {
  activeSortOption.value = option;
  router.push({ query: { ...route.query, sort: option } });
};

watch(
  () => airdropStore.activeCategories,
  (newActiveCategories) => {
    if (newActiveCategories.size === airdropStore.categories.length) {
      selectedCategory.value = "All";
    } else if (newActiveCategories.size === 1) {
      selectedCategory.value = Array.from(newActiveCategories)[0];
    }
  },
  { deep: true }
);

const setGridColumnCount = () => {
  gridColumnCount.value = window.innerWidth > 768 ? 2 : 1;
};

const updateGridColumnCountBasedOnWidth = () => {
  setGridColumnCount();
};

// Fetch airdrops when the component mounts
onMounted(async () => {
  setGridColumnCount();
  window.addEventListener("resize", updateGridColumnCountBasedOnWidth);
  window.addEventListener("keydown", handleKeyDown);
  await airdropStore.fetchAirdrops();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateGridColumnCountBasedOnWidth);
  window.removeEventListener("keydown", handleKeyDown);
});

const categories = airdropStore.categories;
const activeCategories = airdropStore.activeCategories;
const activeSortOption = ref(
  route.query.sort || siteConfig.airdrops.sortOptions[0]
);
const searchQuery = ref(route.query.search || "");
const gridColumnCount = ref(window.innerWidth > 768 ? 2 : 1);

watch(
  route,
  () => {
    initializeFromQuery(
      route.query,
      airdropStore,
      activeSortOption,
      gridColumnCount
    );
  },
  { immediate: true }
);

const filteredItems = computed(() => {
  let items = airdropStore.items.filter(
    (item) =>
      matchesSearch(item, searchQuery.value) &&
      (airdropStore.activeCategories.size === airdropStore.categories.length ||
        airdropStore.activeCategories.has(item.category))
  );

  let statusOrder;
  switch (activeSortOption.value) {
    case "Status":
      statusOrder = ["active", "upcoming", "rumored", "finished"];
      items.sort((a, b) => {
        let statusComparison =
          statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        if (statusComparison !== 0) {
          return statusComparison;
        }
        return a.title.localeCompare(b.title);
      });
      break;
    case "Name":
      items.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case "Newest":
      items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      break;
    case "Oldest":
      items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      break;
    // Add more cases if needed
  }

  return items;
});

const handleSearch = (event) => {
  searchQuery.value = event.target.value;
};

const projectParam = computed(() => route.query.project);
const handleProjectClose = () => {
  if (route.query.project) {
    const updatedQuery = { ...route.query };
    delete updatedQuery.project;
    router.replace({ query: updatedQuery });
  }
};

const isActiveAllCategories = computed(
  () => activeCategories.size === airdropStore.categories.length
);
</script>

<template>
  <div class="md:hero mx-auto p-4 w-full overflow-x-hidden">
    <div class="md:hero-content w-full flex flex-col">
      <div class="text-center mt-24 md:mt-42 pb-4">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageContent?.content?.title || "Airdrops" }}
        </h1>
        <p class="text-xl text-secondary-focus">
          {{
            pageContent?.content?.subtitle ||
            "Earn rewards for being an early user"
          }}
        </p>
      </div>

      <div class="w-full border-0">
        <div class="flex w-full justify-between pt-4">
          <!-- Search Bar with Icon -->
          <div class="relative w-80 lg:w-160 lg:pr-0">
            <font-awesome-icon
              class="absolute left-3 top-1/3 ml-4 mt-4 transform -translate-y-1/2 text-base-content"
              :icon="['fas', 'magnifying-glass']"
            />
            <input
              type="text"
              placeholder="Search..."
              id="search-input"
              class="input input-bordered ml-4 mt-4 pl-10 w-full bg-base-200 text-base-content placeholder-base-content"
              v-model="searchQuery"
              @input="handleSearch"
            />
          </div>
          <!-- Sort & Column Selection -->
          <div
            class="hidden md:flex w-full justify-end pt-4 pr-2 text-base-content"
          >
            <div class="column-selection pr-8">
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 2 }"
                @click="gridColumnCount = 2"
              >
                <font-awesome-icon :icon="['fas', 'table-cells-large']" />
              </div>
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 3 }"
                @click="gridColumnCount = 3"
              >
                <font-awesome-icon :icon="['fas', 'grip']" />
              </div>
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 4 }"
                @click="gridColumnCount = 4"
              >
                <font-awesome-icon :icon="['fas', 'table-cells']" />
              </div>
            </div>
            <p class="mt-4 pr-2">Sort:</p>
            <div class="dropdown dropdown-end text-base-content">
              <label
                tabindex="0"
                class="btn m-1 text-base-content bg-base-200 hover:bg-base-100 w-32"
                >{{ activeSortOption }}</label
              >
              <ul
                tabindex="0"
                class="dropdown-content z-[1] custom-menu p-2 shadow bg-base-100 rounded-box w-52 text-base-content"
              >
                <li
                  v-for="option in siteConfig.airdrops.sortOptions"
                  :key="option"
                  class="hover:text-primary hover:bg-neutral rounded hover:cursor-pointer"
                  :class="{
                    'font-bold text-primary-focus': activeSortOption === option,
                  }"
                >
                  <a @click="updateSortOption(option)">{{ option }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex w-full py-8">
          <div class="w-full pl-4">
            <!-- Dropdown for Categories (Mobile) -->
            <select
              class="block md:hidden input input-bordered w-full max-w-xs bg-base-200 text-base-content"
              v-model="selectedCategory"
              @change="handleCategoryChange"
            >
              <option value="All">All Categories</option>
              <option
                v-for="category in categories"
                :key="category"
                :value="category"
              >
                {{ category }}
              </option>
            </select>
            <!-- All Categories Button -->
            <div class="hidden md:flex flex-wrap">
              <button
                @click="toggleAllCategories"
                class="h-8 w-32 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
                :class="{
                  'active-class': isActiveAllCategories,
                  'inactive-class': !isActiveAllCategories,
                }"
              >
                All Categories
              </button>
              <button
                v-for="category in categories"
                :key="category"
                @click="selectSingleCategory(category)"
                class="h-8 w-24 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
                :class="{
                  'active-class': activeCategories.has(category),
                  'inactive-class': !activeCategories.has(category),
                }"
              >
                {{ category }}
              </button>
            </div>
          </div>
        </div>

        <AirdropGrid
          :sortOption="activeSortOption"
          :activeCategories="activeCategories"
          :items="filteredItems"
          :projectParam="projectParam"
          :gridColumnCount="gridColumnCount"
          @close="handleProjectClose"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.active-class {
  /* Active category button styles (e.g., highlighted) */
  @apply bg-primary text-base-content;
}
.inactive-class {
  /* Inactive category button styles (e.g., faded) */
  @apply bg-base-100 text-base-content;
}

.category-select-button {
  font-family: Lato-Bold;
  font-size: 0.7rem;
  text-transform: uppercase;
}
/* Other styles */

.column-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.column-option {
  @apply border border-base-200 hover:bg-base-100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.column-option.selected {
  @apply border border-base-content bg-base-100;
}

/* Font Awesome Icon Style */
.fa-th-large,
.fa-th,
.fa-th-list {
  font-size: 20px;
}
</style>
