<template>
  <div
    class="relative container mx-auto max-w-screen-xl text-center"
    style="min-height: 100vh"
  >
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-24 md:mt-42 mb-12 text-base-content text-center"
    >
      {{ pageContent.content.title ?? "FAQs" }}
    </h1>
    <FaqSection :faqs="faqs" :isLoading="isLoading" :error="error" />
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import FaqSection from "@/components/faq/FaqSection.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("faq");
useHead(headData);

const faqs = ref([]);
const isLoading = ref(true);
const error = ref(null);

const fetchFAQs = async () => {
  try {
    const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
    const response = await fetch(`${baseUrl}/faq`, {
      headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch FAQs");
    }
    faqs.value = await response.json();
    isLoading.value = false;
  } catch (err) {
    error.value = err.message;
    isLoading.value = false;
  }
};

onMounted(async () => {
  fetchFAQs();
});
</script>
