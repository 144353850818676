<script setup>
import siteConfig from "@/siteConfig.json";
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  matchesSearch,
  initializeFromQuery,
  updateQueryParameters,
} from "@/utilities/directory/functions";
import { useDirectoryStore } from "@/stores/directoryStore";
import { useCoinsStore } from "@/stores/coins";
import { useCollectionStore } from "@/stores/collections";
import DirectoryGrid from "@/components/directory/DirectoryGrid.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
import { handleKeyDown } from "@/utilities/general/hotKeys";
const { pageContent, headData } = usePageData("directory");
useHead(headData);

const route = useRoute();
const router = useRouter();
const directoryStore = useDirectoryStore();
directoryStore.initializeStore();
const coinsStore = useCoinsStore();
const collectionStore = useCollectionStore();

const calculateCumulativeMarketCap = ref((project) => {
  let totalMarketCap = 0;
  // Find the current price of SOL from the "coins" collection
  const sol = coinsStore.coins.find((c) => c.symbol === "SOL");
  const solPrice = sol ? sol.currentPriceCoinGecko : 0;

  // Calculate market cap from coins
  if (project.marketCapCoins && project.marketCapCoins.length > 0) {
    project.marketCapCoins.forEach((coinSymbol) => {
      const coin = coinsStore.coins.find((c) => c.symbol === coinSymbol);
      if (coin) {
        totalMarketCap += coin.marketCap;
      }
    });
  }
  // Calculate market cap from NFTs
  if (project.marketCapNFTs && project.marketCapNFTs.length > 0) {
    project.marketCapNFTs.forEach((nftSymbol) => {
      const collection = collectionStore.collections.find(
        (c) => c.symbol === nftSymbol
      );
      if (collection) {
        // Assuming the backend provides floorPrice in SOL and totalSupply as integers
        const nftMarketCap =
          collection.floorPrice * collection.totalSupply * solPrice;
        totalMarketCap += nftMarketCap;
      }
    });
  }
  return totalMarketCap;
});

function calculateAndStoreMarketCaps() {
  directoryStore.items.forEach((project) => {
    project.calculatedMarketCap = calculateCumulativeMarketCap.value(project);
    // console.log(
    //   `Calculated Market Cap for ${project.title}: `,
    //   project.calculatedMarketCap
    // );
  });
}

const selectedCategory = ref("All");

watch(
  () => directoryStore.activeCategories,
  (newActiveCategories) => {
    if (newActiveCategories.size === categories.length) {
      selectedCategory.value = "All";
    } else if (newActiveCategories.size === 1) {
      selectedCategory.value = Array.from(newActiveCategories)[0];
    }
  },
  { deep: true }
);

const selectSingleCategory = (category) => {
  if (category === "All") {
    directoryStore.setActiveCategories("All");
  } else {
    directoryStore.activeCategories.clear();
    directoryStore.activeCategories.add(category);
  }
  selectedCategory.value = category;
  updateQueryParameters(
    router,
    route,
    directoryStore.activeCategories,
    gridColumnCount.value
  );
};

// const handleKeyDown = (event) => {
//   if ((event.metaKey || event.ctrlKey) && event.key === "k") {
//     event.preventDefault();
//     document.getElementById("search-input").focus();
//   }
// };

const handleCategoryChange = () => {
  //console.log("Category changed to", selectedCategory.value);

  // if (selectedCategory.value === "All") {
  //   directoryStore.setActiveCategories("All");
  // } else {
  //   directoryStore.setActiveCategories(selectedCategory.value);
  // }

  // // Convert the Set of categories into an array
  // const categoriesArray = Array.from(directoryStore.activeCategories);
  // //console.log("categoriesArray:", categoriesArray);

  // // Update the URL query parameters
  // updateQueryParameters(router, route, categoriesArray, gridColumnCount.value);
  selectSingleCategory(selectedCategory.value);
};

const setGridColumnCount = () => {
  gridColumnCount.value = window.innerWidth > 768 ? 3 : 1;
};

// Fetch projects when the component mounts
onMounted(async () => {
  setGridColumnCount();
  window.addEventListener("resize", updateGridColumnCountBasedOnWidth);
  window.addEventListener("keydown", handleKeyDown);

  // Fetch projects, coins, and collections
  await directoryStore.fetchProjects();
  await coinsStore.fetchCoins();
  await collectionStore.fetchCollections();

  // Calculate market caps after all data is loaded
  calculateAndStoreMarketCaps();

  // Ensure the child component updates after data is loaded
  if (route.query.project) {
    projectParam.value = route.query.project;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateGridColumnCountBasedOnWidth);
  window.removeEventListener("keydown", handleKeyDown);
});

const categories = directoryStore.categories;
const activeCategories = directoryStore.activeCategories;
const activeSortOption = ref(
  route.query.sort || siteConfig.directory.sortOptions[0]
);
const searchQuery = ref(route.query.search || "");
const gridColumnCount = ref(window.innerWidth > 768 ? 3 : 1); // Default to 3 columns

watch(
  route,
  () => {
    initializeFromQuery(
      route.query,
      directoryStore,
      activeSortOption,
      gridColumnCount
    );
  },
  { immediate: true }
);

const toggleCategory = (category) => {
  selectSingleCategory(category);
  // directoryStore.toggleCategory(category);
  // updateQueryParameters(router, route, activeCategories, gridColumnCount.value); // Pass the current gridColumnCount
};

const updateSortOption = (option) => {
  activeSortOption.value = option;
  router.push({ query: { ...route.query, sort: option } });
};

// Computed property to filter items based on the search query
const filteredItems = computed(() => {
  let items = directoryStore.items.filter((item) =>
    matchesSearch(item, searchQuery.value)
  );

  switch (activeSortOption.value) {
    case "Market Cap":
      items = sortItemsByMarketCap(items);
      break;
    case "Name":
      items = items.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case "Newest":
      items = items.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      break;
    case "Oldest":
      items = items.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      break;
    // Add more cases if needed
  }

  return items;
});

function sortItemsByMarketCap(items) {
  return items
    .slice()
    .sort((a, b) => b.calculatedMarketCap - a.calculatedMarketCap);
}

// Update search query as user types
const handleSearch = (event) => {
  searchQuery.value = event.target.value;
};

const projectParam = computed(() => route.query.project);
// Function to handle the closing of the project detail view
const handleProjectClose = () => {
  // Check if the 'project' query parameter exists
  if (route.query.project) {
    const updatedQuery = { ...route.query };
    delete updatedQuery.project; // Remove the 'project' parameter
    router.replace({ query: updatedQuery });
  }
};

const updateGridColumnCountBasedOnWidth = () => {
  gridColumnCount.value = window.innerWidth > 768 ? 3 : 1;
};

// watch(gridColumnCount, () => {
//   updateGridColumnCountBasedOnWidth();
// });

const isActiveAllCategories = computed(
  () => activeCategories.size === categories.length
);

const toggleAllCategories = () => {
  // if (isActiveAllCategories.value) {
  //   directoryStore.activeCategories.clear();
  // } else {
  //   directoryStore.categories.forEach((category) => {
  //     directoryStore.activeCategories.add(category);
  //   });
  // }
  // updateQueryParameters(router, route, activeCategories, gridColumnCount.value); // Pass the current gridColumnCount
  selectSingleCategory("All");
};
</script>

<template>
  <div class="md:hero mx-auto p-4 w-full max-w-full overflow-x-hidden">
    <div class="md:hero-content w-full flex flex-col">
      <div class="text-center mt-24 md:mt-42 pb-4">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageContent?.content?.title || "Directory" }}
        </h1>
        <p class="text-xl text-secondary-focus">
          {{
            pageContent?.content?.subtitle ||
            "Explore the best of the Solana ecosystem"
          }}
        </p>
      </div>

      <div class="w-full border-base-200 border-0">
        <div class="block md:flex w-full justify-between pt-4">
          <!-- Search Bar with Icon -->
          <div class="relative w-80 lg:w-160 lg:pr-0">
            <font-awesome-icon
              class="absolute left-3 top-1/3 ml-4 mt-4 transform -translate-y-1/2 text-base-content"
              :icon="['fas', 'magnifying-glass']"
            />
            <input
              type="text"
              placeholder="Search..."
              id="search-input"
              class="input input-bordered ml-4 mt-4 pl-10 w-full min-w-lg bg-base-200 text-base-content placeholder-base-content"
              v-model="searchQuery"
              @input="handleSearch"
            />
          </div>
          <!-- Sort & Column Selection -->
          <div
            class="hidden md:flex w-full justify-end pt-4 pr-2 text-base-content"
          >
            <div class="column-selection pr-8">
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 2 }"
                @click="gridColumnCount = 2"
              >
                <font-awesome-icon :icon="['fas', 'table-cells-large']" />
              </div>
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 3 }"
                @click="gridColumnCount = 3"
              >
                <font-awesome-icon :icon="['fas', 'grip']" />
              </div>
              <div
                class="column-option hover:text-base-content"
                :class="{ selected: gridColumnCount === 4 }"
                @click="gridColumnCount = 4"
              >
                <font-awesome-icon :icon="['fas', 'table-cells']" />
              </div>
            </div>
            <p class="mt-4 pr-2">Sort:</p>
            <div
              class="dropdown dropdown-end text-base-content hover:text-base-content"
            >
              <label
                tabindex="0"
                class="btn m-1 text-base-content bg-base-200 hover:bg-base-100 w-32"
                >{{ activeSortOption }}</label
              >
              <ul
                tabindex="0"
                class="dropdown-content z-[1] custom-menu p-2 shadow bg-base-100 rounded-box w-52 text-base-content hover:text-base-content"
              >
                <li
                  v-for="option in siteConfig.directory.sortOptions"
                  :key="option"
                  class="hover:text-primary hover:bg-neutral rounded hover:cursor-pointer"
                  :class="{
                    'font-bold text-primary-focus': activeSortOption === option,
                  }"
                >
                  <a @click="updateSortOption(option)">{{ option }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex w-full py-8">
          <div class="w-85 md:w-full pl-4">
            <!-- Dropdown for Categories (Mobile) -->
            <select
              class="block md:hidden input input-bordered w-full max-w-xs bg-base-200 text-base-content"
              v-model="selectedCategory"
              @change="handleCategoryChange"
            >
              <option value="All">All Categories</option>
              <option
                v-for="category in categories"
                :key="category"
                :value="category"
              >
                {{ category }}
              </option>
            </select>
            <!-- All Categories Button -->
            <div class="hidden md:flex flex-wrap">
              <button
                @click="toggleAllCategories"
                class="h-8 w-32 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
                :class="{
                  'active-class': isActiveAllCategories,
                  'inactive-class': !isActiveAllCategories,
                }"
              >
                All Categories
              </button>
              <button
                v-for="category in categories"
                :key="category"
                @click="toggleCategory(category)"
                class="h-8 w-24 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
                :class="{
                  'active-class': activeCategories.has(category),
                  'inactive-class': !activeCategories.has(category),
                }"
              >
                {{ category }}
              </button>
            </div>
          </div>
        </div>

        <DirectoryGrid
          :sortOption="activeSortOption"
          :activeCategories="activeCategories"
          :items="filteredItems"
          :projectParam="projectParam"
          :gridColumnCount="gridColumnCount"
          @close="handleProjectClose"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.active-class {
  /* Active category button styles (e.g., highlighted) */
  @apply bg-primary text-base-content;
}
.inactive-class {
  /* Inactive category button styles (e.g., faded) */
  @apply bg-base-100 text-base-content;
}

.category-select-button {
  font-family: Lato-Bold;
  font-size: 0.7rem;
  text-transform: uppercase;
}
/* Other styles */

.column-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.column-option {
  @apply border border-base-200 hover:bg-base-100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.column-option.selected {
  @apply border border-base-content bg-base-100;
}

/* .column-option:hover {
  background-color: #44403c;
} */

/* Font Awesome Icon Style */
.fa-th-large,
.fa-th,
.fa-th-list {
  font-size: 20px;
}
</style>
