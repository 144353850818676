<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg max-w-2xl mx-auto"
  >
    <h2 class="text-4xl font-bold text-center mt-6 mb-6">Settings</h2>
    <div v-if="isLoading" class="text-center text-base-content">
      <p>Loading...</p>
    </div>
    <div v-else-if="user" class="space-y-6">
      <!-- Theme Selection -->
      <div v-if="siteConfig.settingsOptions.themeSelection" class="space-y-2">
        <label for="theme" class="text-lg font-semibold mt-6"
          >Theme Selection</label
        >
        <div class="relative">
          <select
            id="theme"
            v-model="session.theme"
            @change="updateTheme"
            :class="[
              'w-full p-2 border border-neutral bg-base-100 rounded appearance-none',
              { 'border-green-500': showSaved.theme },
            ]"
          >
            <option v-for="theme in themes" :key="theme" :value="theme">
              {{ theme }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-base-content"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <p v-if="showSaved.theme" class="text-sm text-green-500">Saved</p>
        <div v-if="!showSaved.theme" class="h-6"></div>
      </div>

      <!-- Base Currency Dropdown -->
      <div v-if="siteConfig.settingsOptions.baseCurrency" class="space-y-2">
        <label for="baseCurrency" class="text-lg font-semibold"
          >Base Currency</label
        >
        <div class="relative">
          <select
            id="baseCurrency"
            v-model="user.baseCurrency"
            @change="updateField('baseCurrency')"
            :class="[
              'w-full p-2 border border-neutral bg-base-100 rounded appearance-none',
              { 'border-green-500': showSaved.baseCurrency },
            ]"
          >
            <option
              v-for="currency in currencies"
              :key="currency"
              :value="currency"
            >
              {{ currency }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-base-content"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <p v-if="showSaved.baseCurrency" class="text-sm text-green-500">
          Saved
        </p>
        <div v-if="!showSaved.baseCurrency" class="h-6"></div>
      </div>

      <!-- Private RPC Input -->
      <div v-if="siteConfig.settingsOptions.rpcEndpoint" class="space-y-2">
        <label for="rpcUrl" class="text-lg font-semibold"
          >Private RPC Endpoint</label
        >
        <p class="text-sm text-base-content">
          Please disable any IP address or domain whitelists for your RPC.
        </p>
        <input
          id="rpcUrl"
          v-model="user.rpcUrl"
          :placeholder="user.rpcUrl || 'Enter your private RPC endpoint'"
          @blur="updateField('rpcUrl')"
          :class="[
            'w-full p-2 border border-neutral bg-base-100 rounded',
            { 'border-green-500': showSaved.rpcUrl },
          ]"
          autocomplete="off"
        />
        <p v-if="showSaved.rpcUrl" class="text-sm text-green-500">Saved</p>
        <div v-if="!showSaved.rpcUrl" class="h-6"></div>
      </div>

      <!-- Developer Settings Section -->
      <div class="space-y-4">
        <h3 class="text-2xl font-semibold">Developer Settings</h3>
        <label class="flex items-center space-x-2">
          <input
            type="checkbox"
            v-model="developerSettingsEnabled"
            @change="updateDeveloperSettings"
            class="toggle toggle-primary"
          />
          <span>Enable Developer Settings</span>
        </label>

        <!-- Claim Key Section -->
        <div v-if="developerSettingsEnabled" class="mt-4 space-y-4">
          <h4 class="text-xl font-semibold">Claim Key</h4>
          <p class="text-sm text-red-500">
            Warning: Your claim key gives access to claim all rewards associated
            with your account. Only enable it if you know what you are doing.
          </p>
          <label class="flex items-center space-x-2">
            <input
              type="checkbox"
              v-model="claimKeyEnabled"
              @change="updateClaimKeySettings"
              class="toggle toggle-warning"
            />
            <span>Enable Claim Key</span>
          </label>

          <!-- Reset Claim Key Button -->
          <div v-if="claimKeyEnabled" class="mt-4">
            <button class="btn btn-error" @click="showResetKeyModal = true">
              Reset Claim Key
            </button>
          </div>

          <!-- Reset Claim Key Modal -->
          <div v-if="showResetKeyModal" class="modal modal-open">
            <div class="modal-box">
              <h3 class="font-bold text-lg">
                Are you sure you want to reset your claim key?
              </h3>
              <p class="py-4 text-sm text-red-500">
                This action is irreversible. Please store your new key securely,
                we are not able to recover it for you.
              </p>
              <div v-if="newClaimKey" class="mt-4 mb-4">
                <strong>Your new claim key:</strong>
                <div
                  class="bg-base-200 p-2 rounded mt-2 flex justify-between items-center"
                >
                  <span class="text-base-content">{{ newClaimKey }}</span>
                  <button
                    @click="copyNewClaimKey"
                    class="btn btn-ghost btn-sm px-2"
                    title="Copy claim key"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="modal-action">
                <button class="btn" @click="closeResetKeyModal">Close</button>
                <button
                  v-if="!newClaimKey"
                  class="btn btn-error"
                  @click="resetClaimKey"
                >
                  Reset Key
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="text-center text-base-content">
      <p>You are not logged in.</p>
      <router-link :to="'/login'">
        <button
          @click="login"
          class="mt-4 px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
        >
          Login
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import eventBus from "@/eventBus";
import { useSessionStore } from "@/stores/session";
import siteConfig from "@/siteConfig.json";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const session = useSessionStore();
const user = ref(null);
const isLoading = ref(true);
const developerSettingsEnabled = ref(false);
const claimKeyEnabled = ref(false);
const showResetKeyModal = ref(false);
const newClaimKey = ref(null);
const claimKey = ref("");

const initialValues = ref({});
const showSaved = ref({
  rpcUrl: false,
  theme: false,
  baseCurrency: false,
});

const themes = ["customdark", "customlight"];
const currencies = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "HKD",
  "INR",
  "NZD",
  "SGD",
  "KRW",
  "SEK",
  "NOK",
  "MXN",
  "ZAR",
  "BRL",
  "RUB",
  "TRY",
  "BTC",
  "ETH",
  "SOL",
];

const fetchUser = async () => {
  try {
    console.log("Fetching user data...");
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
      { withCredentials: true }
    );
    user.value = response.data;
    initialValues.value = JSON.parse(JSON.stringify(user.value));

    developerSettingsEnabled.value =
      user.value.developerSettingsEnabled || false;
    claimKeyEnabled.value = user.value.claimKeyEnabled || false;
    claimKey.value = user.value.claimKey || "";

    session.setUser(response.data);

    if (user.value.theme) {
      session.setTheme(user.value.theme);
    }

    if (!user.value.baseCurrency) {
      user.value.baseCurrency = "USD";
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    user.value = null;
  } finally {
    isLoading.value = false;
  }
};

const updateField = async (field) => {
  if (!user.value[field]) {
    user.value[field] = "";
  }
  if (user.value[field] !== initialValues.value[field]) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
        { [field]: user.value[field] },
        { withCredentials: true }
      );
      console.log(`${field} updated successfully`);
      showSaved.value[field] = true;
      initialValues.value[field] = user.value[field];
      setTimeout(() => {
        showSaved.value[field] = false;
      }, 2000);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      user.value[field] = initialValues.value[field];
    }
  } else {
    console.log(`${field} unchanged, no update needed`);
  }
};

const updateTheme = async () => {
  try {
    await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
      { theme: session.theme },
      { withCredentials: true }
    );
    showSaved.value.theme = true;
    user.value.theme = session.theme;
    initialValues.value.theme = session.theme;
    setTimeout(() => {
      showSaved.value.theme = false;
    }, 2000);
  } catch (error) {
    console.error(`Error updating theme:`, error);
    session.setTheme(user.value.theme);
  }
};

const updateDeveloperSettings = async () => {
  try {
    await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
      { developerSettingsEnabled: developerSettingsEnabled.value },
      { withCredentials: true }
    );
    user.value.developerSettingsEnabled = developerSettingsEnabled.value;
    toast.success("Developer settings updated", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: session.theme === "customdark" ? "dark" : "light",
    });
  } catch (error) {
    console.error("Error updating developer settings:", error);
    developerSettingsEnabled.value = user.value.developerSettingsEnabled;
    toast.error("Failed to update developer settings", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: session.theme === "customdark" ? "dark" : "light",
    });
  }
};

const updateClaimKeySettings = async () => {
  try {
    await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
      { claimKeyEnabled: claimKeyEnabled.value },
      { withCredentials: true }
    );
    user.value.claimKeyEnabled = claimKeyEnabled.value;
    toast.success("Claim key settings updated", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: session.theme === "customdark" ? "dark" : "light",
    });
  } catch (error) {
    console.error("Error updating claim key settings:", error);
    claimKeyEnabled.value = user.value.claimKeyEnabled;
    toast.error("Failed to update claim key settings", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: session.theme === "customdark" ? "dark" : "light",
    });
  }
};

const resetClaimKey = async () => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/reset-claim-key`,
      {},
      {
        headers: { "X-API-KEY": user.value.apiKey },
        withCredentials: true,
      }
    );

    if (response.data && response.data.newClaimKey) {
      newClaimKey.value = response.data.newClaimKey;
      toast.success("Claim key reset successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: session.theme === "customdark" ? "dark" : "light",
      });
    } else {
      throw new Error("Failed to reset claim key");
    }
  } catch (error) {
    console.error("Error resetting claim key:", error);
    toast.error("Failed to reset claim key", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: session.theme === "customdark" ? "dark" : "light",
    });
  }
};

const closeResetKeyModal = () => {
  showResetKeyModal.value = false;
  newClaimKey.value = null;
};

const copyNewClaimKey = () => {
  if (newClaimKey.value) {
    navigator.clipboard
      .writeText(newClaimKey.value)
      .then(() => {
        toast.success("New claim key copied to clipboard", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: session.theme === "customdark" ? "dark" : "light",
        });
      })
      .catch((err) => {
        console.error("Failed to copy new claim key:", err);
        toast.error("Failed to copy new claim key", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: session.theme === "customdark" ? "dark" : "light",
        });
      });
  }
};

const handleLogin = () => {
  console.log("Login event received");
  fetchUser();
};

const login = () => {
  eventBus.emit("showLogin");
};

onMounted(() => {
  fetchUser();
  eventBus.on("login", handleLogin);
});
</script>
