<script setup>
import { onMounted, nextTick, ref, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import MobileMenu from "./MobileMenu.vue";
import { useSessionStore } from "@/stores/session";
// import { useSiteDataStore } from "@/stores/siteDataStore";
import LoginButton from "../login/LoginButton.vue";
import siteConfig from "@/siteConfig.json";

const router = useRouter();
const route = useRoute();
const isMenuOpen = ref(false);
const sessionStore = useSessionStore();
// const siteDataStore = useSiteDataStore();

const isHomePage = computed(() => route.name === "Home");
const navbarBackgroundClass = computed(() =>
  isHomePage.value ? "bg-transparent" : "bg-base-300 bg-opacity-80"
);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const currentTheme = computed(() => sessionStore.theme);

const getLogoPath = (size) =>
  computed(() => {
    const theme = currentTheme.value === "customlight" ? "light" : "dark";
    return siteConfig[theme]?.logos?.[size]?.path || "";
  });

const getLogoAlt = (size) =>
  computed(() => {
    const theme = currentTheme.value === "customlight" ? "light" : "dark";
    return siteConfig[theme]?.logos?.[size]?.alt || "";
  });

const smallLogoSrc = getLogoPath("small");
const largeLogoSrc = getLogoPath("large");
const smallLogoAlt = getLogoAlt("small");
const largeLogoAlt = getLogoAlt("large");

const logoWidth = computed(() => {
  // if the site url from the site config is cashcaptcha.com, then return 24, otherwise return 12
  return siteConfig.url === "cashcaptcha.com" ? "w-60" : "w-40";
});

const mobileLogoWidth = computed(() => {
  return siteConfig.url === "cashcaptcha.com" ? "w-16 -mt-2 ml-2" : "w-14";
});

// New computed property for main navigation items from siteConfig
const mainNavItems = computed(() => siteConfig.mainNav || []);

onMounted(async () => {
  // sleep 100ms to let the user profile load
  // await new Promise((resolve) => setTimeout(resolve, 100));

  // if (!sessionStore.user) {
  //   await sessionStore.fetchUser();
  // }

  // if (!siteDataStore.siteData) {
  //   await siteDataStore.fetchSiteData();
  // }

  nextTick(() => {
    const button = document.querySelector(".swv-button-trigger");
    if (button) {
      button.innerText = "Login";

      const observer = new MutationObserver((mutations) => {
        for (let mutation of mutations) {
          if (mutation.type === "childList" && mutation.addedNodes.length) {
            const modalTitle = document.querySelector(".swv-modal-title");
            if (modalTitle) {
              modalTitle.innerText = "Sign In With Solana";
              observer.disconnect();
              break;
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      button.addEventListener("click", () => {
        observer.observe(document.body, { childList: true, subtree: true });
      });
    }
  });
});

watch(
  () => sessionStore.isLoggedIn,
  (newValue) => {
    if (newValue) {
      sessionStore.fetchUser();
    }
  }
);

watch(
  () => router.currentRoute.value.path,
  () => {
    sessionStore.fetchUser();
  }
);
</script>

<template>
  <div>
    <!-- NavBar / Header -->
    <div
      class="navbar fixed top-0 left-0 z-50 w-full max-w-full flex flex-row justify-between items-center"
      :class="navbarBackgroundClass"
    >
      <!-- Logo -->
      <router-link to="/" class="shrink-0">
        <img
          v-if="smallLogoSrc"
          :src="smallLogoSrc"
          :alt="smallLogoAlt"
          class="p-3 block lg:hidden"
          :class="mobileLogoWidth"
        />
        <img
          v-if="largeLogoSrc"
          :src="largeLogoSrc"
          :alt="largeLogoAlt"
          class="w-40 p-3 hidden lg:block"
          :class="logoWidth"
        />
      </router-link>

      <!-- Nav Links and Wallet Button -->
      <div class="flex items-center">
        <!-- Nav Links -->
        <div class="hidden md:flex md:gap-2 nav-icon-group">
          <router-link
            v-for="item in mainNavItems"
            :key="item.name"
            :to="item.url"
            class="nav-icon-link"
          >
            <font-awesome-icon class="nav-icon" :icon="['fas', item.icon]" />
            <span class="nav-text">{{ item.name }}</span>
          </router-link>
        </div>

        <!-- Login Button -->
        <div class="lg:ml-4 mr-2 lg:mr-4">
          <LoginButton />
        </div>

        <!-- Hamburger Menu Icon -->
        <button @click="toggleMenu" class="md:hidden p-2">
          <font-awesome-icon
            :icon="['fas', isMenuOpen ? 'times' : 'bars']"
            class="text-base-content text-2xl"
          />
        </button>
      </div>
    </div>

    <!-- Mobile Menu -->
    <MobileMenu :isOpen="isMenuOpen" @close="isMenuOpen = false" />
  </div>
</template>

<style scoped>
.nav-icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.nav-icon {
  @apply text-base-content hover:text-secondary hover:opacity-70;
  font-size: 1.4rem;
  margin: 0px 20px;
}

.nav-text {
  @apply text-base-content;
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%) translateY(-5px);
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease-in-out, transform 0.3s ease-in-out,
    visibility 0.1s;
}

.nav-icon-link:hover .nav-text {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.nav-icon-link:hover .nav-icon {
  transform: scale(1.1);
}

.nav-icon-link:hover .nav-text {
  display: block;
}
</style>
