<!-- MemeCoinSwapPage.vue | @/pages/MemeCoinSwapPage.vue -->
<script setup>
import { ref, onMounted } from "vue";
import TokenSwap from "@/components/swap/TokenSwap";
import { useCoinsStore } from "@/stores/coins";
import { liquidStakingTokens } from "@/config/liquidStakingTokens.js";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("buy-liquid-staking-tokens");
useHead(headData);

const allowedTokensForSecondDropdown = liquidStakingTokens;
const coinsStore = useCoinsStore();
let tokenObjectsForSecondDropdown = ref([]);
let dataLoaded = ref(false);
onMounted(async () => {
  tokenObjectsForSecondDropdown.value = await coinsStore.getTokensByAddress(
    allowedTokensForSecondDropdown
  );
  dataLoaded.value = true; // Set the flag to true once data is fetched
});
</script>

<template>
  <div class="md:hero mx-auto">
    <div class="md:hero-content flex flex-col w-full">
      <div class="text-center mt-24 md:mt-42 pb-4">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageContent?.content?.title || "Liquid Staking Token Swap" }}
        </h1>
        <p class="text-xl text-secondary-focus">
          {{ pageContent?.content?.subtitle || "" }}
        </p>
      </div>
      <token-swap
        v-if="dataLoaded"
        :allowed-tokens="tokenObjectsForSecondDropdown"
      />
    </div>
  </div>
</template>

<style scoped>
h3 {
  font-size: 1.2rem;
  font-family: Lato-Regular;
}
</style>
