<template>
  <div v-if="pageContent" class="container mx-auto px-4 py-8">
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mb-12 text-base-content text-center mt-24 md:mt-42"
    >
      Support
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <SupportCard
        v-for="card in supportCards"
        :key="card.title"
        :icon="card.icon"
        :title="card.title"
        :description="card.description"
        :buttonText="card.buttonText"
        :buttonAction="card.buttonAction"
        :active="card.active"
      />
    </div>
  </div>
  <div v-else>
    Loading...
    <pre>{{ JSON.stringify(pageContent, null, 2) }}</pre>
  </div>
</template>

<script setup>
import { ref } from "vue";
import SupportCard from "@/components/support/SupportCard.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
import siteConfig from "@/siteConfig.json";

const { pageContent, headData } = usePageData("support");
useHead(headData);

const supportCards = ref(siteConfig.support.availableOptions);

// const supportCards = ref([
//   {
//     icon: "fa-book",
//     title: "Browse the knowledge base",
//     description:
//       "Find answers to common questions in our comprehensive knowledge base.",
//     buttonText: "Explore KB",
//     buttonAction: () => {
//       // Implement knowledge base navigation logic
//       console.log("Navigating to knowledge base");
//     },
//   },
//   {
//     icon: "fa-comments",
//     title: "Chat with an agent",
//     description:
//       "Get real-time assistance from our support team through live chat.",
//     buttonText: "Start Chat",
//     buttonAction: () => {
//       // Implement chat initiation logic
//       console.log("Initiating chat with support agent");
//     },
//   },
//   {
//     icon: "fa-envelope",
//     title: "Send us an email",
//     description:
//       "For complex inquiries, send us an email and we'll get back to you promptly.",
//     buttonText: "Compose Email",
//     buttonAction: () => {
//       // Implement email composition logic
//       console.log("Opening email composition");
//     },
//   },
// ]);
</script>
